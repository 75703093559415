import React from 'react'
import Layout from "../components/share/layout";
import Nosotroscomponent from "../components/nosotroscomponent"
import '../styles/font-awesome-4.7.0/css/font-awesome.css'
import Footer from '../components/share/footer';

const nosotros = () => {
	return (
		<Layout
			title="Quiénes somos | UPC Cultural"
      metaTitle="Quiénes somos | UPC Cultural"
      description="Conoce al comité ejecutivo y al equipo detrás de UPC Cultural, encargados de compartir experiencias de interés y reflexión sobre diversas manifestaciones. "
      subtitile="Conoce al comité ejecutivo y al equipo detrás de UPC Cultural, encargados de compartir experiencias de interés y reflexión sobre diversas manifestaciones. "
      metaDescripcin="Conoce al comité ejecutivo y al equipo detrás de UPC Cultural, encargados de compartir experiencias de interés y reflexión sobre diversas manifestaciones. "
      imgurl="//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg"
      keywords="quienes somos upc cultural, nosotros upc cultural, equipo upc cultural"
		>
			<Nosotroscomponent />
			<Footer />
		</Layout>
	)
}

export default nosotros
