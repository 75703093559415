import React from 'react'
import Img1 from '../images/Escalera.jpg'
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

const IMGBACKGROUND = styled.div`
    background: url(${Img1}) center center no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 758px;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`;

const Nosotroscomponent = () => {
	const Datos = useStaticQuery(
		graphql`
    	query {
      	allContentfulGeneral(filter: {node_locale: {eq: "en-US"}}) {
          nodes {
            quienesSomos {
              json
            }
            childContentfulGeneralQuienesSomosDescripcionRichTextNode {
              json
            }
          }
        }        
    	}
  `)
	const options = {
		renderNode: {
			[BLOCKS.HEADING_1]: ({ content }, children) => {
		console.log(children);
		return (
			<h2>{content[0].value}</h2>

		)
		}
		}
	}
	return (
		<div>
			<div className="service about-us ">
				<div className="service-subcontainer about-us-subcontainer">
					<IMGBACKGROUND className="img-background-responsive-about-us">
					</IMGBACKGROUND>
					<div className="menu-left menu-left-2 with-automatico">
						<div style={{ 'height': '100px' }}>
							<h1>QUIÉNES SOMOS</h1>
						</div>
						<div className="subtittle2 DocumentReactComponentsResponsive">
							{documentToReactComponents(Datos.allContentfulGeneral.nodes[0].quienesSomos.json)}
							<div className="footer-about-us-responsive">
								<div>
									<h3>UPC Cultural es + Arte y cultura</h3>
								</div>
								<div className="text-about-us-responsive DocumentReactComponentsResponsive">
									{documentToReactComponents(Datos.allContentfulGeneral.nodes[0].childContentfulGeneralQuienesSomosDescripcionRichTextNode.json, options)}
								</div>
								<div className="cotainer-contact">
									<p>Contáctanos:</p>
								</div>
								<h3 className="page-email"><a style={{ 'color': '#fff' }} href="https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=upccultural@upc.pe" target="_blank" rel="noreferrer">upccultural@upc.pe</a></h3>
							</div>
						</div>
					</div>
				</div>
				<IMGBACKGROUND className="img-background-about-us">
					<div className="text-about-us DocumentReactComponents">
						{documentToReactComponents(Datos.allContentfulGeneral.nodes[0].childContentfulGeneralQuienesSomosDescripcionRichTextNode.json, options)}
					</div>
					<div style={{textAlign: "center"}}>
						<img src="https://ik.imagekit.io/asu/infografi%CC%81a_xV6Qe0Vnq.png" style={{margin: "20px", maxWidth: "1000px"}} />
					</div>
				</IMGBACKGROUND>
			</div>
		</div>
	)
}

export default Nosotroscomponent
